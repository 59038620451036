import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import { breakpoints, Body, Box, Button, red, white } from 'dma-ui-kit';
import useBreakpoint from 'use-breakpoint';

const ImageDetector = ({ endpoint, action }) => {
  /**
   * A React component that interacts with the user's camera to capture and
   * send photos to an API. It includes functionality to start the camera,
   * capture a photo, and send the captured photo to an API endpoint.
   */
  const [captureEnabled, setCaptureEnabled] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const { breakpoint } = useBreakpoint(breakpoints);

  useEffect(() => {
    startCamera();
  }, []);

  const startCamera = async () => {
    try {
      const permissions = await navigator.permissions.query({ name: 'camera' });
      if (permissions.state === 'denied') {
        setError('Camera access is denied.');
        return;
      }
    } catch (err) {
      setError('Error obtaining camera access: ' + err.message);
    }

    try {
      const constraints = {
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          facingMode: { ideal: "environment" },
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
    } catch (err) {
      setError("Error accessing camera: " + err.message);
    }
  };

  const returnObject = (object) => {
    object && action(object);
  }

  const capturePhoto = async () => {
    // Stop an existing timeout from causing the message to disappear
    timeoutId && clearTimeout(timeoutId);    

    setCaptureEnabled(false);
    setError('Detecting ... please wait');

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set canvas dimensions to match the video dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the current video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas content to a data URL in PNG format
    const photo = canvas.toDataURL("image/png");

    // Send the photo to the API
    await sendPhotoToApi(photo);

    setCaptureEnabled(true);
  };

  const sendPhotoToApi = async (photo) => {
    try {
      const { data } = await axios.post(endpoint, { image: photo });

      if (data.status && data.status === "SUCCESS" && data.objectID) {
        setError("Object identified as " + data.objectID);
        setTimeoutId(
          setTimeout(() => {
            setError('');
          }, 2500),
        );
        returnObject(data.objectID);
      } else {
        setError('Unable to identify object');
        setTimeoutId(
          setTimeout(() => {
            setError('');
          }, 5000),
        );
      }
    } catch (err) {
      setError('AI appears to be offline');
      setTimeoutId(
        setTimeout(() => {
          setError('');
        }, 5000),
      );
    }
  };

  return (
    <Box center maxWidth={(breakpoint == 'mobile') ? '340px' : '640px'} backgroundColor={red} padding={(breakpoint == 'mobile') ? '2px' : '4px'} margin={'auto'} >
      <Box center backgroundColor={white} pb={'5px'} pt={'20px'}>
        <video ref={videoRef} width={(breakpoint == 'mobile') ? '320px' : '620px'} autoPlay playsInline muted></video>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </Box>
      <Box center backgroundColor={white} pb={'20px'} pt={'5px'}>
        <Button label={'Detect Object'} variant={'outline'} compact={true} onClick={capturePhoto} color={red} disabled={!captureEnabled} />
      </Box>
      {error && <Box margin={'4px'} backgroundColor={red} center><Body bold={true} color={white}>{error}</Body></Box>}
    </Box>
  );
};

ImageDetector.propTypes = {
  endpoint: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default ImageDetector;
